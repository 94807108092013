import axios, { AxiosError } from 'axios';
// import axiosRetry from 'axios-retry';
import Cookies from 'js-cookie';
// eslint-disable-next-line import/extensions
// import 'broadcastchannel-polyfill';
import { BASE_URL } from '@/env';
import { notify } from '@sobrus-com/sobrus-design-system-v2';
// import { deleteAllIndexedDBs } from './openIndexedDB';
// import { objectToString } from '@/helpers/functions';
import i18n from '@/i18n/i18next';

// import { DbNames } from '@/data/editorData';
let isDisconnected = false;

const AXIOS = axios.create({
  baseURL: BASE_URL,
  responseType: 'json',
  withCredentials: true,
  headers: {
    Accept: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7',
  },
  // timeout: 5000,
});

AXIOS.interceptors.request.use(async (config: any) => {
  const token = Cookies.get('csrfToken');
  console.log(token);
  if (token) {
    if (config.method !== 'get') config.headers['X-CSRF-Token'] = token; // Ajouter le token CSRF dans les en-têtes de la requête
  }

  return config;
});

AXIOS.interceptors.response.use(
  (response) => {
    isDisconnected = false;
    return response;
  },
  async function (error) {
    if (
      (!error.response || error?.code === 'ERR_NETWORK' || (error instanceof AxiosError && error.code === AxiosError.ERR_NETWORK)) &&
      !isDisconnected
    ) {
      return;
    } else if (
      error?.response?.status === 401 &&
      window.location.pathname !== '/login' &&
      window.location.pathname !== '/login/password' &&
      window.location.pathname !== '/auth/password-recovery' &&
      window.location.pathname !== '/auth/password-recovery/success' &&
      window.location.pathname !== '/auth/password-reset' &&
      window.location.pathname !== '/auth/password-reset/mobile' &&
      window.location.pathname !== '/signup' &&
      window.location.pathname !== '/authorize'
    ) {
      localStorage.clear();
      window.location.href = '/login';
    } else if (error?.message === 'canceled' || error.code === 'ERR_CANCELED') {
      return;
    } else if (error?.response?.status === 404) {
      throw error;
    } else if (error?.response?.status === 403) {
      notify({
        type: 'danger',
        msg: i18n.t('Access Denied!'),
        delay: 5000,
      });
    } else if (!isDisconnected) {
      let message = ``;
      if (error?.response?.data?.errorMessage) {
        message = error?.response?.data?.errorMessage;
      }
      throw message;
    } else {
      throw error;
    }
    throw error;
  },
);

export default AXIOS;
